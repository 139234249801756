import React from 'react';
import "./culture.css"// Importing the CSS file

const Culture = () => {
  return (
    <div className="culture-container">
      <section className="national-cultures">
        <h1>National cultures</h1>
        <p>
          Cultures are what make a country unique and interesting. Each 
          country 
          has different cultural activities and cultural 
          <span className="highlight"> rituals</span>. Culture includes material goods, the things the people use and produce. Culture is also the beliefs and values of the people and the ways they think about and understand the world and their own lives.
        </p>
        <p>
          Different countries have different cultures. For example, some older 
          <span className="highlight"> Japanese </span>
          people wear 
          <span className="highlight"> kimonos</span>, arrange 
          <span className="highlight"> flowers</span> in vases, and have 
          <span className="highlight"> tea ceremonies</span>. Some countries oppose some things in their culture, like 
          <span className="highlight"> discrimination </span> or 
          <span className="highlight"> religion</span>. Some countries also have multiple cultures within them.
        </p>
      </section>

      <section className="regional-cultures">
        <h2>Regional or non-regional cultures</h2>
        <p>
          Culture can also vary within a 
          <span className="highlight"> region</span>, 
          <span className="highlight"> society</span>, or subgroup. A workplace may have a specific culture that sets it apart from similar workplaces. A region of a country may have a different culture than the rest of the country. For example, in a large country like 
          <span className="highlight"> China </span> or 
          <span className="highlight"> Canada</span> a region may have a distinctive way of talking, different types of music, and different types of dances.
        </p>
        <p>
          A group who acts or speaks differently may be said to be, or have, a 
          <span className="highlight"> subculture</span>.
        </p>
        <p>
          Ethnic groups such as the 
          <span className="highlight"> Romani people</span> in 
          <span className="highlight"> Europe</span> have a distinct culture.
        </p>
      </section>
      <div className="culture-container">
      <section className="evolution-of-culture">
        <h1>Evolution of culture</h1>
        <p>
          The direction of biologic evolution toward greater expansion and security of life can be seen from another point of view: 
          the advance from instinctive behaviour (
          i.e., responses determined by intrinsic properties of the organism to learned and freely variable behaviour, patterns of which may be acquired and transmitted from one individual and generation to another, and finally to a system of things and events, the essence of which is meanings that cannot be comprehended by the senses alone. 
          This system is, of course, culture, and the species is the human species. Culture is a man-made environment, brought into existence by the ability to symbol.
        </p>
        <p>
          Once established, culture has a life of its own, so to speak; that is, it is a 
          <span className="highlight"> continuum</span> of things and events in a 
          <a href="#" className="link"> cause and effect </a> 
          relationship; it flows down through time from one generation to another. Since its inception 1,000,000 or more years ago, this culture—with its language, beliefs, tools, codes, and so on—has had an existence external to each individual born into it.
        </p>
        <p>
          The function of this external, man-made environment is to make life secure and enduring for the society of human beings living within the cultural system. 
          Thus, culture may be seen as the most recent, the most highly developed means of promoting the security and continuity of life, in a series that began with the simple reflex.
        </p>
      </section>
    </div>
    </div>
    
  );
};

export default Culture;
