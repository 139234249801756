import React from "react";
import "../header/head.css";
// import Logo3 from './public';

const Head = () => {
  return (
    <div className="logo-container">
      <svg
        width="300"
        height="60"
        viewBox="0 0 300 120"
         xmlns="http://www.w3.org/2000/svg"
      >
         {/* <div className="logo-set">
      {logos.map((logo) => (
        <img key={logo.id} src={tech-logo-footer-new} alt={tech-logo-footer-new} className="logo" />
      ))}
    </div>  */}
        {/* Gradient for text */}
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#007bff', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#00c6ff', stopOpacity: 1 }} />
          </linearGradient>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#ff4500', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#ff8c00', stopOpacity: 1 }} />
          </linearGradient>
        </defs>

        {/* "Bijnor" Text with gradient */}
        <text
          x="10%" 
          y="50%" 
          dominantBaseline="middle" 
          textAnchor="start" 
          fontSize="50" 
          fill="url(#gradient1)" 
          fontFamily="Poppins, Arial" 
          fontWeight="bold"
          className="bijnor-text"
        >
          Bijnor
        </text>

        {/* "Today" Text with gradient */}
        <text 
          x="60%" 
          y="50%" 
          dominantBaseline="middle" 
          textAnchor="start" 
          fontSize="40" 
          fill="url(#gradient2)" 
          fontFamily="Poppins, Arial" 
          fontWeight="bold"
          className="today-text"
        >
          Today
        </text>

        {/* Shadow effect for depth */}
        <text 
          x="10%" 
          y="50%" 
          dominantBaseline="middle" 
          textAnchor="start" 
          fontSize="50" 
          fill="none" 
          stroke="rgba(0, 0, 0, 0.15)" 
          strokeWidth="2" 
          fontFamily="Poppins, Arial" 
          fontWeight="bold"
        >
          Bijnor
        </text>
        <text 
          x="60%" 
          y="50%" 
          dominantBaseline="middle" 
          textAnchor="start" 
          fontSize="40" 
          fill="none" 
          stroke="rgba(0, 0, 0, 0.15)" 
          strokeWidth="2" 
          fontFamily="Poppins, Arial" 
          fontWeight="bold"
        >
          Today
        </text>
      </svg>
    </div>
  );
};

export default Head;
