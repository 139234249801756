import React from "react"
import Discover from "./discover/Discover"
// import Hero from "./hero/Hero"
import Homes from "./mainContent/homes/Home"
import { HeroComponent } from "./hero/HeroComponent"

const Homepages = () => {
  return (
    <>
      <HeroComponent />
      <Homes />
      <Discover />
    </>
  )
}

export default Homepages
