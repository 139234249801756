import React from 'react';
import '../styles/UpdateModal.css'; // Add your styles here

const UpdateModal = ({ isOpen, onClose, update }) => {
    if (!isOpen || !update) return null; // Don't render if not open or no update

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{update.headline}</h2>
                <p>{update.content}</p>
                {update.image && <img src={update.image} alt={update.headline} className="modal-image" />}
               
                <button className='modal-button' onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default UpdateModal;
