import React from "react"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container'>
          <div className='box logo'>
          {/* <h1 style={{ maxHeight:"100%" , fontSize:"2rem"}}>BiJNOR TODAY</h1> */}
            <img src='../images/tech-logo-footer.png' alt='' />
            {/* <p>In Bijnor today, traffic disruptions are expected due to railway crossing maintenance on the Bijnor-Haridwar road​(
              Hindustan
              ). A police encounter led to the arrest of five suspects in an animal theft case​(
              ABP Live
             
              ).</p> */}
            {/* <i className='fa fa-envelope'></i> */}
            <span> bijnortoday3@gmail.com </span> <br />
            {/* <i className='fa fa-headphones'></i> */}
            {/* <span> +91 60521488</span> */}
          </div>
          <div className='box'>
            <h3>SPORT</h3>
            <div className='item'>
              <img src='../images/hero/hero1.jpg' alt='' />
              <p>BijnorToday Cover nature news</p>
            </div>
            <div className='item'>
              <img src='../images/hero/hero2.jpg' alt='' />
              <p>Bijnor Today is a popular newspaper</p>
            </div>
          </div>
          <div className='box'>
            <h3>CRICKET</h3>
            <div className='item'>
              <img src='../images/hero/hero3.jpg' alt='' />
              <p>People like the Bijnor Today Newspaper</p>
            </div>
            <div className='item'>
              <img src='../images/hero/hero4.jpg' alt='' />
              <p>Technlogy  industry waits for Policy</p>
            </div>
          </div>
          <div className='box'>
            <h3> SocialMedia</h3>
            {/*<i className='fa fa-chevron-right'></i>*/}
            <ul>
            <a href='https://www.facebook.com/share/SSEFABUEBSprhVWV/?mibextid=qi2Omg' target='_blank' rel='noopener noreferrer' style={{ color: "black" }}>
            <i className='fab fa-facebook-f'></i> <span> </span>
            <span>Facebook</span>
          </a>
          <br/><br/>
          <a href='https://www.instagram.com/bijnortoday?igsh=a2FhMjB0YXVxdDh0' target='_blank' rel='noopener noreferrer' style={{ color: "black" }}>
             <i className='fab fa-instagram'></i> <span> </span>
             <span>Instagram</span> 
          </a><br/><br/>
              <a href='https://youtube.com/@bijnortoday3951?si=2uI188C_re6FkgaC' target='_blank' rel='noopener noreferrer' style={{ color: "black" }}>
              <i className='fab fa-youtube'></i><span> </span>
            <span>Youtube</span>
          </a><br/><br/>
          <a href=' https://x.com/Bijnortoday' target='_blank' rel='noopener noreferrer' style={{ color: "black" }}>
           <i className='fab fa-twitter'></i> <span> </span>
             <span>Twitter</span>  
          </a>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal  '>
        <div className='container flexSB'>
          <p>© all rights reserved 2024</p>
          {/* <p>
            made with <i className='fa fa-heart'></i> by gorkhcoder
          </p> */}
        </div>
      </div>
    </>
  )
}

export default Footer
