import React from "react";
import { Link } from "react-router-dom";
import "./hero.css"

const Card = ({ item: { id, cover, category, title } }) => { // Fixed typo: catgeory to category
  return (
    <div className='box'>
      <div className='img'>
        <img src={cover} alt='' />
      </div>
      <div className='text'>
        <span className='category'>{category}</span>
        <Link to={`/SinglePage/${id}`}>
          <h1 className='titleBg' style={{color:"white"}}>{title}</h1>
        </Link>
      </div>
    </div>
  );
};

export default Card;
