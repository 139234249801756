import React, { useState } from "react"
import Head from "./Head"
import "./header.css"
import { Link } from "react-router-dom"
import Dropdown from 'react-bootstrap/Dropdown';

const Header = () => {
  const [navbar, setNavbar] = useState(false)

  return (
    <>
      <header style={{ display: "flex" }}>
        <div className='paddingSmall'>
          <Head />
        </div>
        <div className='paddingSmall' style={{ width: "100%", display: 'flex', justifyContent: 'end' }}>
          <nav>
            <ul style={{ marginTop: '20px' }} className={navbar ? "navbar" : "flex"} onClick={() => setNavbar(false)}>


              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/Daily-Update-News'>Local </Link>
              </li>
              <li>
                <Link to='/national-news'>National </Link>
              </li>
              <li>
                <Link to='/internationals-news'>International</Link>
              </li>
              <li>
                <Link to='/politic-news'>Politics</Link>
              </li>
              <li>
                <Link to='/sports-news'>Sports</Link>
              </li>
              <li>
                <Link to='/entertainments-news'>Entertain</Link>
              </li>
              <li>
                <Link to='/education-news'>Education </Link>
              </li>


              {/* <li>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Category
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link to="sports-news"><Dropdown.Item href="sports-news">Sport</Dropdown.Item></Link>
                    <Link to="education-news"><Dropdown.Item href="education-news">Education</Dropdown.Item></Link>
                    <Link to="entertainments-news"><Dropdown.Item href="entertainments-news">Entertainment</Dropdown.Item></Link>
                    <Link to="internationals-news"><Dropdown.Item href="internationals-news">International</Dropdown.Item></Link>
                    <Link to="national-news"><Dropdown.Item href="national-news">National</Dropdown.Item></Link>
                    <Link to="politic-news"><Dropdown.Item href="politic-news">Politic</Dropdown.Item></Link>

                  </Dropdown.Menu>
                </Dropdown>
              </li> */}

              <li>
                <Link to='/news-paper'>News Paper</Link>
              </li>


            </ul>
            <button className='barIcon' onClick={() => setNavbar(!navbar)}>
              {navbar ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}
            </button>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header
