import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Header from "./components/common/header/Header"
import "./App.css"
import Homepages from "./components/home/Homepages"
import Culture from './components/culture/Culture'
import Footer from "./components/common/footer/Footer"
import SinglePage from "./components/singlePage/SinglePage"
import Politics from "./components/common/poltices/Politics"
import SportsCoverage from './components/common/Sportcover/SportsCoverage'
import DailyUpdate from "./components/DailyUpdate/DailyUpdate"
import Sports from "./components/DailyUpdate/Sports"
import Education from "./components/DailyUpdate/Education"
import EntertainmentsData from "./components/DailyUpdate/EntertainmentsData"
import InternationalsData from "./components/DailyUpdate/InternationalsData"
import NationalData from "./components/DailyUpdate/NationalData"
import PoliticsData from "./components/DailyUpdate/PoliticsData"
import Newspaper from "./components/NewsPaper/Newspaper"

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Homepages} />
          {/* <Route path='/singlepage/:id' exact component={SinglePage} /> */}
          <Route exact path='/Culture' component={Culture} />
          <Route exact path='/Politics' component={Politics} />
          <Route exact path='/SportsCoverage' component={SportsCoverage} />
          <Route exact path="/Daily-Update-News" component={DailyUpdate }/>
          <Route exact path="/sports-news" component={Sports }/>
          <Route exact path="/education-news" component={Education }/>
          <Route exact path="/entertainments-news" component={EntertainmentsData }/>
          <Route exact path="/internationals-news" component={InternationalsData }/>
          <Route exact path="/national-news" component={NationalData }/>
          <Route exact path="/politic-news" component={PoliticsData }/>


          <Route exact path="/news-paper" component={Newspaper}/>
          
          
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App
