import React, { useState, useEffect } from "react";
import "./Popular.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from "../../../common/heading/Heading";

const Popular = () => {
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 2,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
        },
      },
    ],
  };

  const [heroes, setHeroes] = useState([]);

  const fetchHeroData = async () => {
    try {
      const urls = [
        'https://bijnortoday.com/internationalsdata/internationals', // International
        'https://bijnortoday.com/dailyupdate/daily',               // Daily Update
        'https://bijnortoday.com/sportsdata/sport',                 // Sports
        'https://bijnortoday.com/politicsdata/politics',           // Politics
        'https://bijnortoday.com/educationsdata/educations',       // Education
        'https://bijnortoday.com/entertainmentsdata/entertainments' // Entertainment
      ];

      const responses = await Promise.all(urls.map(url => fetch(url)));

      const dataPromises = responses.map(async (response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return await response.json();
      });

      const allData = await Promise.all(dataPromises);

      // Create a new array for each category and repeat the sequence
      const categories = [
        "International",
        "Daily Update",
        "Sports",
        "Politics",
        "Education",
        "Entertainment"
      ];

      const combinedData = [];

      // Loop through each data array and build the combined data
      categories.forEach((category, index) => {
        const items = allData[index] || [];
        items.slice(0, 2).forEach((item) => {
          combinedData.push({
            id: item.id || 1,
            cover: item.image ? `https://bijnortoday.com/${item.image}` : "../images/hero/placeholder.jpg",
            category: category,
            title: item.headline || "No Title Available",
          });
        });
      });

      // Repeat the same sequence again
      combinedData.push(...combinedData);

      setHeroes(combinedData); // Set the state with the repeated data

    } catch (error) {
      console.error("Error fetching hero data:", error);
      setHeroes([]);
    }
  };

  useEffect(() => {
    fetchHeroData();
  }, []);

  return (
    <section className='popular'>
      <Heading title='Popular' />
      <div className='content'>
        {heroes.length > 0 ? (
          <Slider {...settings}>
            {heroes.map((val) => (
              <div className='items' key={val.id}>
                <div className='box shadow'>
                  <div className='images row'>
                    <div className='img'>
                      <img src={val.cover} alt='shbc' />
                    </div>
                    <div className='category category1'>
                      <span>{val.category}</span>
                    </div>
                  </div>
                  <div className='text row'>
                    <h1 className='title'>{val.title.slice(0, 40)}</h1>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>No hero data available.</p>
        )}
      </div>
    </section>
  );
};

export default Popular;
