import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateModal from './UpdateModal.js'; 
import "../styles/DailyData.css";

function DailyUpdate() {
    const [dailyUpdates, setDailyUpdates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedUpdateIndex, setSelectedUpdateIndex] = useState(null);

    const fetchDailyUpdates = async () => {
        try {
            const response = await fetch('https://bijnortoday.com/dailyupdate/daily');
            if (!response.ok) {
                throw new Error('Failed to fetch updates');
            }
            const data = await response.json();
            const formattedData = data.map(update => ({
                ...update,
                image: update.image ? `https://bijnortoday.com/${update.image}` : null
            }));
            setDailyUpdates(formattedData.reverse());
        } catch (error) {
            toast.error('Failed to fetch updates: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDailyUpdates();
    }, []);

    const openUpdateModal = (index) => {
        setSelectedUpdateIndex(index);
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
        setSelectedUpdateIndex(null);
    };

    return (
        <>
            <div className={loading ? "container-sm" : "container"}>
                {loading ? (
                    <p className="loading-text">Loading updates...</p>
                ) : dailyUpdates.length > 0 ? (
                    dailyUpdates.map((update, index) => (
                        <div
                            key={update.id}
                            className="row mar-pad update-card d-flex justify-content-between"
                            onClick={() => openUpdateModal(index)}
                        >
                            {/* Image Column */}
                            <div className='col-12 col-sm-6 d-flex justify-content-center'>
                                {update.image && (
                                    <img
                                        src={update.image}
                                        alt={update.headline}
                                        className="card-image"
                                        style={{ width: '100%', height: '300px', objectFit: 'cover' }}
                                    />
                                )}
                            </div>

                            {/* Content Column */}
                            <div className='col-12 col-sm-6 d-flex flex-column'>
                                <h3 className="card-title m-3">{update.headline}</h3>
                                <div className="card-content-container">
                                    <p className="card-content">
                                        {update.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-updates-text">No updates available. Please add some updates.</p>
                )}
                <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
                <UpdateModal isOpen={isUpdateModalOpen} onClose={closeUpdateModal} update={dailyUpdates[selectedUpdateIndex]} />
            </div>


        </>
    );
}

export default DailyUpdate;
