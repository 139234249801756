import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Newspaper.css'; // Import CSS for animations

function Newspaper() {
    const [pdfs, setPdfs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false); // State to handle search animation

    useEffect(() => {
        const fetchPdfs = async () => {
            try {
                const response = await fetch('https://bijnortoday.com/pdf-uploads/pdfs');
                const data = await response.json();
                setPdfs(data.reverse()); // Reverse the data to show the latest PDFs first
            } catch (error) {
                toast.error('Error fetching PDFs');
            } finally {
                setLoading(false);
            }
        };

        fetchPdfs();
    }, []);

    // Filter the PDFs based on the search query
    const filteredPdfs = pdfs.filter(pdf =>
        pdf.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Show only the latest PDF (either from search results or all)
    const latestPdf = filteredPdfs.length > 0 ? filteredPdfs[0] : null;

    return (
        <>
            <div className={`search-bar-container ${isSearchActive ? 'active' : ''}`}>
                {/* Search Bar */}
                <input
                    type="text"
                    placeholder="Search PDFs..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="animated-search-bar"
                    onFocus={() => setIsSearchActive(true)} // Trigger animation on focus
                    onBlur={() => setIsSearchActive(false)} // Reset animation on blur
                />
            </div>

            <div className="pdf-all-container">
                {loading ? (
                    <p>Loading PDFs...</p>
                ) : latestPdf ? (
                    <div className="pdf-viewer">
                        <iframe
                            src={`https://bijnortoday.com${latestPdf.path}#toolbar=0`} // Hide the toolbar
                            title={latestPdf.name}
                            width="100%"
                            height="600px"
                            style={{ border: 'none' }} // Optional: Remove border
                        />
                    </div>
                ) : (
                    <p>No PDFs match your search.</p>
                )}
                <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            </div>
        </>
    );
}

export default Newspaper;
