import React, { useEffect, useState } from "react"
import Slider from "react-slick"
// import { ppost } from "../../../../dummyData"
import Heading from "../../../common/heading/Heading"
import "./ppost.css"

// copy same code of popular


const Ppost = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  const [entertainment, setEntertainment] = useState([]); // State for entertainment data

  const fetchHeroData = async () => {
    try {
      // Fetch entertainment data
      const entertainmentResponse = await fetch('https://bijnortoday.com/entertainmentsdata/entertainments');
      if (!entertainmentResponse.ok) {
        throw new Error('Failed to fetch entertainment data');
      }
      const entertainmentData = await entertainmentResponse.json();

      // Process the fetched data to get the latest 4 items
      const formatData = (data) => {
        if (Array.isArray(data) && data.length > 0) {
          return data.slice(-4).map(item => ({
            id: item.id || 1,
            cover: item.image ? `https://bijnortoday.com/${item.image}` : "../images/hero/placeholder.jpg",
            category: item.category || "Entertainment",
            title: item.headline || "No Title Available",
          }));
        }
        return []; // Reset if no data
      };

      // Set formatted entertainment data
      setEntertainment(formatData(entertainmentData));

    } catch (error) {
      console.error("Error fetching entertainment data:", error);
      setEntertainment([]); // Reset on error
    }
  };

  useEffect(() => {
    fetchHeroData(); // Fetch the hero data when the component mounts
  }, []);


  return (
    <>


      <section className='popularPost'>
        <Heading title='Popular Posts' />
        <div className='content'>
          <Slider {...settings}>
            {entertainment.length > 0 ? (
              entertainment.map(val => (
                <div className='items' key={val.id}> {/* Add key to the outer div */}
                  <div className='box shadow'>
                    <div className='images'>
                      <div className='img'>
                        <img src={val.cover} alt='' />
                      </div>
                    </div>
                    <div className='text'>
                      <h1 className='title'>{val.title.slice(0, 40)}...</h1>
                     
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No daily update hero data available.</p>
            )}

          </Slider>
        </div>
      </section>
    </>
  )
}

export default Ppost
