import React, { useState, useEffect } from 'react';
import Card from './Card'; // Import the Card component
import './hero.css';

// Component for handling hero data
export const HeroComponent = () => {
  const [heroes, setHeroes] = useState({
    international: null,
    dailyUpdate: null,
    sports: null,
    politics: null,
  }); // State for all hero data

  const fetchHeroData = async () => {
    try {
      // Fetch international data
      const internationalResponse = await fetch('https://bijnortoday.com/internationalsdata/internationals');
      if (!internationalResponse.ok) {
        throw new Error('Failed to fetch international data');
      }
      const internationalData = await internationalResponse.json();

      // Fetch daily update data
      const dailyUpdateResponse = await fetch('https://bijnortoday.com/dailyupdate/daily');
      if (!dailyUpdateResponse.ok) {
        throw new Error('Failed to fetch daily update data');
      }
      const dailyUpdateData = await dailyUpdateResponse.json();

      // Fetch sports data
      const sportsResponse = await fetch('https://bijnortoday.com/sportsdata/sport');
      if (!sportsResponse.ok) {
        throw new Error('Failed to fetch sports data');
      }
      const sportsData = await sportsResponse.json();

      // Fetch politics data
      const politicsResponse = await fetch('https://bijnortoday.com/politicsdata/politics');
      if (!politicsResponse.ok) {
        throw new Error('Failed to fetch politics data');
      }
      const politicsData = await politicsResponse.json();

      // Process each data source
      const formatData = (data, defaultCategory, defaultTitle) => {
        if (Array.isArray(data) && data.length > 0) {
          const latestItem = data[data.length - 1]; // Get the last item
          return {
            id: latestItem.id || 1,
            cover: latestItem.image ? `https://bijnortoday.com/${latestItem.image}` : "../images/hero/placeholder.jpg",
            category: latestItem.category || defaultCategory,
            title: latestItem.headline || defaultTitle,
          };
        }
        return null; // Reset if no data
      };

      // Set formatted data for all categories
      setHeroes({
        international: formatData(internationalData, "International News", "No Title Available"),
        dailyUpdate: formatData(dailyUpdateData, "Latest News", "No Title Available"),
        sports: formatData(sportsData, "Sports", "No Title Available"),
        politics: formatData(politicsData, "Politics", "No Title Available"),
      });

    } catch (error) {
      console.error("Error fetching hero data:", error);
      setHeroes({ international: null, dailyUpdate: null, sports: null, politics: null }); // Reset on error
    }
  };

  useEffect(() => {
    fetchHeroData(); // Fetch the hero data when the component mounts
  }, []);

  return (
    <section className='hero'>
      <div className='container'>
       
        {heroes.dailyUpdate ? (
          <Card key={heroes.dailyUpdate.id} item={heroes.dailyUpdate} /> // Render Card for daily update hero item
        ) : (
          <p>No daily update hero data available.</p>
        )}
        {heroes.sports ? (
          <Card key={heroes.sports.id} item={heroes.sports} /> // Render Card for sports hero item
        ) : (
          <p>No sports hero data available.</p>
        )}
        {heroes.politics ? (
          <Card key={heroes.politics.id} item={heroes.politics} /> // Render Card for politics hero item
        ) : (
          <p>No politics hero data available.</p>
        )}
        {heroes.international ? (
          <Card key={heroes.international.id} item={heroes.international} /> // Render Card for international hero item
        ) : (
          <p>No international hero data available.</p>
        )}
      </div>
    </section>
  );
};
