import React,{useState,useEffect} from "react"
import "./tpost.css"

const Tpost = () => {

  const [dailyUpdates, setDailyUpdates] = useState([]); // State for daily update hero data

  const fetchHeroData = async () => {
    try {
      // Fetch daily update data
      const response = await fetch('https://bijnortoday.com/dailyupdate/daily');
      if (!response.ok) {
        throw new Error('Failed to fetch daily update data');
      }
      const data = await response.json();

      // Process the daily update data
      const formatData = (data) => {
        return data.map(item => ({
          id: item.id || 1,
          cover: item.image ? `https://bijnortoday.com/${item.image}` : "../images/hero/placeholder.jpg",
          title: item.headline || "No Title Available",
        }));
      };

      // Set formatted daily update data, limited to top 3 items
      setDailyUpdates(formatData(data).slice(0, 3).reverse());

    } catch (error) {
      console.error("Error fetching hero data:", error);
      setDailyUpdates([]); // Reset on error
    }
  };

  useEffect(() => {
    fetchHeroData(); // Fetch the hero data when the component mounts
  }, []);

  return (
    <>
      <section className='tpost'>
        <div className='heighligths News'>
          {dailyUpdates.length > 0 ? (
            dailyUpdates.map(update => (
              <div className='box flexSB'>
                <div className='img'>
                  <img src={update.cover} alt='' />
                </div>
                <div className='text'>
                  <h1 className='title'>{update.title.slice(0, 35)}...</h1>
                </div>
              </div>
            ))
          ) : (
            <p>No daily update hero data available.</p>
          )}
        </div>
      </section>
    </>
  )
}

export default Tpost

