import React from "react"

const SocialMedia = () => {
  return (
    <>
      <section className='social'>
        {/* <div className='socBox'>
         <i className='fab fa-facebook-f'></i>
          <span>12,740 Likes</span>
        </div> */}
        <div className='socBox'>
          <a href='https://www.facebook.com/share/SSEFABUEBSprhVWV/?mibextid=qi2Omg' target='_blank' rel='noopener noreferrer' style={{ color: "white" }}>
            <i className='fab fa-facebook-f'></i>
            <span>12,740 Likes</span>
          </a>
        </div>

        <div className='socBox'>
          <a href='https://youtube.com/@bijnortoday3951?si=2uI188C_re6FkgaC' target='_blank' rel='noopener noreferrer' style={{ color: "white" }}>
            <i className='fab fa-pinterest'></i>
            <span>5,600 Fans</span>
          </a>
        </div>
        <div className='socBox'>
          <a href=' https://x.com/Bijnortoday' target='_blank' rel='noopener noreferrer' style={{ color: "white" }}>
            <i className='fab fa-twitter'></i>
            <span>8,700 Followers</span>
          </a>
        </div>
        <div className='socBox'>
          <a href='https://www.instagram.com/bijnortoday?igsh=a2FhMjB0YXVxdDh0' target='_blank' rel='noopener noreferrer' style={{ color: "white" }}>
            <i className='fab fa-instagram'></i>
            <span>22,700 Followers</span>
          </a>
        </div>
        <div className='socBox'>
          <a href='https://youtube.com/@bijnortoday3951?si=2uI188C_re6FkgaC' target='_blank' rel='noopener noreferrer' style={{ color: "white" }}>
            <i className='fab fa-youtube'></i>
            <span>2,700 Subscribers</span>
          </a>
        </div>
      </section>
    </>
  )
}

export default SocialMedia
